<template>
  <div class="po-issuer-payment-plan">
    <div class="d-flex mb-4 gutter-mx-3 search-toolbar" style="justify-content: start">
      <!-- Column Select -->
      <div class="column-filter">
        <v-autocomplete dense solo hide-details flat outlined :items="searchColumns" v-model="searchColumn">
          <template v-slot:append>
            <v-icon> mdi-chevron-down </v-icon>
          </template>
        </v-autocomplete>
      </div>

      <!-- Column Search -->
      <div class="search">
        <v-text-field
          dense
          outlined
          hide-details
          v-model="searchText"
          placeholder="Insert Keyword to search"
          background-color="white"
          @keyup.enter="getPaymentPlan(true)"></v-text-field>
      </div>

      <v-btn color="secondary" icon @click="getPaymentPlan(true)" style="margin: 0; align-self: center">
        <v-icon> mdi-magnify </v-icon>
      </v-btn>
    </div>

    <div class="custom-table">
      <table>
        <thead>
          <tr>
            <th
              v-for="column in tableColumns"
              :key="`thead ${column.text}`"
              :class="{ pointer: column.canSort !== false }"
              @click="column.canSort !== false ? changeSort(column.value) : ''">
              <span>{{ column.text }}</span>

              <i class="fa-solid fa-sort-down" v-if="sort.key === column.value && sort.order === 'desc'"></i>
              <i class="fa-solid fa-sort-up" v-if="sort.key === column.value && sort.order === 'asc'"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(paymentPlan, paymentPlanIndex) in paymentPlans">
            <tr :key="`tr ${paymentPlanIndex}`">
              <td v-for="(column, columnIndex) in tableColumns" :key="`td ${column.text} ${columnIndex}`">
                <span v-if="column.excludeMatching !== true">
                  {{ paymentPlan[column.value] }}
                </span>

                <!-- No -->
                <span v-else-if="column.value === 'sysRegDt'">
                  <template v-if="sort.key === column.value && sort.order === 'asc'">
                    {{ (page - 1) * itemsPerPage + (paymentPlanIndex + 1) }}
                  </template>
                  <template v-else>
                    {{ totalCnt - paymentPlanIndex - (page - 1) * itemsPerPage }}
                  </template>
                </span>

                <!-- PO# -->
                <span
                  v-else-if="column.value === 'poNo'"
                  class="pointer underline"
                  @click="movePODetail(paymentPlan.rmpoUUID)">
                  {{ paymentPlan[column.value] }}
                </span>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <div class="text-center pt-2" v-if="pageCount > 0">
      <v-pagination v-model="page" :length="pageCount" color="secondary" :total-visible="$pagePV"></v-pagination>
    </div>
  </div>
</template>

<script>
import codeHandler from "@/utils/codeHandler";
import { fixedNumber, hasValue, numberWithComma } from "@/utils/util";
export default {
  data() {
    return {
      paymentPlans: [],
      searchColumn: "ALL",
      searchText: "",
      page: 1,
      totalCnt: 0,
      itemsPerPage: 10,
      sort: { key: "sysRegDt", order: "desc" },
      columns: [
        //text : thead 표시
        //value : field Name
        //tableShow : table 표시 여부
        //searchShow : 필터 셀렉트박스 표시 여부
        //excludeMatching : 테이블 표시시 별도 컨트롤 필요 여부 (false일 경우 value로 표기만 하면 됨.)
        //canSort : 정렬 가능 여부
        { text: "ALL", value: "ALL", tableShow: false },
        { text: "No", value: "sysRegDt", searchShow: false, excludeMatching: true },
        { text: "PO RECEIVER", value: "spplrName" },
        { text: "PO#", value: "poNo", excludeMatching: true },
        { text: "TOTAL VALUE", value: "amount" },
        { text: "CURRENCY", value: "currency" },
        { text: "DATE OF PO", value: "sysUdtDt" },
        { text: "SHIP DATE", value: "shipDate" },
        { text: "PAYMENT TERM", value: "paymentTerm" },
        { text: "PAYMENT DATE", value: "paymentDate" },
      ],
    };
  },
  methods: {
    getPaymentPlan(changeFilters) {
      this.$emit("loadingChanged", true);
      if (changeFilters) this.page = 1;

      this.$http
        .get("/financial/po-issuer-payment-plan", {
          data: {
            cmpyUUID: this.$store.getters.getCmpyUUID,
            currentPageNo: this.page,
            filterColumn: this.searchColumn,
            searchText: this.searchText,
            sortColumn: this.sort.key,
            sortType: this.sort.order,
          },
        })
        .then(res => {
          if (res.data.status === 200) {
            if (Array.isArray(res.data.data)) {
              this.paymentPlans = res.data.data.map(p => ({
                ...p,
                amount: numberWithComma(fixedNumber(p.amount, this.$mathFix, true)) || 0,
                shipDate: this.$m(p.shipDate).format("yyyy-MM-DD") || "-",
                paymentDate: this.$m(p.paymentDate).format("yyyy-MM-DD") || "-",
                sysUdtDt: this.$m(p.sysUdtDt).format("yyyy-MM-DD") || "-",
              }));
              this.totalCnt = res.data.data[0]?.totalCnt || 0;
            }
          } else codeHandler(res.data);
        })
        .finally(() => this.$emit("loadingChanged", false));
    },
    changeSort(value) {
      if (this.sort.key === value) {
        this.sort.order = this.sort.order === "desc" ? "asc" : "desc";
      } else {
        this.sort.key = value;
        this.sort.order = "desc";
      }
      this.getPaymentPlan(true);
    },
    movePODetail(rmpoUUID) {
      let routeLink = this.$router.resolve({ name: "issuedRMPODetail", params: { poUUID: rmpoUUID } });
      window.open(routeLink.href, "_blank");
    },
    hasValue,
    numberWithComma,
  },
  computed: {
    tableColumns() {
      return this.columns.filter(c => c.tableShow !== false);
    },
    searchColumns() {
      return this.columns.filter(c => c.searchShow !== false);
    },
    pageCount() {
      return Math.ceil((this.totalCnt || 0) / this.itemsPerPage);
    },
  },
  watch: {
    page() {
      this.getPaymentPlan();
    },
  },
  created() {
    this.getPaymentPlan();
  },
};
</script>

<style></style>
