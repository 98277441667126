<template>
  <div class="financial-plan">
    <div class="page-info">
      <div class="page-title">
        <p class="title-1">Financial Plan</p>
      </div>
    </div>

    <v-card outlined flat rounded="xl" class="pa-8">
      <v-tabs v-model="tabIndex">
        <v-tab @click="$refs.financialReport && $refs.financialReport.getFinancialReport()">FINANCIAL REPORT</v-tab>
        <v-tab @click="$refs.initialSalesAmount && $refs.initialSalesAmount.getinitialSalesAmounts()">
          INITIAL SALES AMOUNT
        </v-tab>
        <v-tab @click="$refs.poIssuerPaymentPlan && $refs.poIssuerPaymentPlan.getPaymentPlan(false)">
          PO ISSUER PAYMENT PLAN
        </v-tab>
        <!-- <v-tab>INVOICE ISSUER PAYMENT PLAN</v-tab> -->
      </v-tabs>
      <div class="contents mt-2">
        <v-tabs-items v-model="tabIndex">
          <v-tab-item>
            <financialReport @loadingChanged="loadingChanged" ref="financialReport" />
          </v-tab-item>
          <v-tab-item> <initialSalesAmount @loadingChanged="loadingChanged" ref="initialSalesAmount" /></v-tab-item>
          <v-tab-item> <poIssuerPaymentPlan @loadingChanged="loadingChanged" ref="poIssuerPaymentPlan" /> </v-tab-item>
          <!-- <v-tab-item> <invoiceIssuerPaymentPlan @loadingChanged="loadingChanged" /> </v-tab-item> -->
        </v-tabs-items>
      </div>
    </v-card>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { toCamelCase } from "@/utils/util";
import FinancialReport from "@/components/company/financialPlan/FinancialReport.vue";
import InitialSalesAmount from "@/components/company/financialPlan/initialSalesAmount/InitialSalesAmount.vue";
// import InvoiceIssuerPaymentPlan from "@/components/company/financialPlan/InvoiceIssuerPaymentPlan.vue";
import PoIssuerPaymentPlan from "@/components/company/financialPlan/PoIssuerPaymentPlan.vue";
export default {
  components: {
    FinancialReport,
    InitialSalesAmount,
    // InvoiceIssuerPaymentPlan,
    PoIssuerPaymentPlan,
  },
  data() {
    return {
      tabIndex: 0,
      loading: false,
    };
  },
  methods: {
    loadingChanged(val) {
      this.loading = val;
    },
    toCamelCase,
  },
};
</script>

<style></style>
