<template>
  <div class="order-recap mt-4">
    <div class="title d-flex">
      <h3>INITIAL SALES ORDER RECAP</h3>
      <v-spacer />
      <template v-if="!editMode">
        <v-btn
          outlined
          color="secondary"
          small
          :disabled="!hasValue(salesAmount.cbdTitle)"
          @click="changeEditMode(true)">
          MODIFY
        </v-btn>
      </template>

      <template v-if="editMode">
        <v-btn outlined color="secondary" small :loading="saveLoading" @click="save"> SAVE </v-btn>
        <v-btn class="ml-1" outlined color="secondary" small @click="changeEditMode(false, true)"> CANCEL </v-btn>
      </template>
    </div>
    <table class="custom-table">
      <thead>
        <tr>
          <th>FLOW</th>
          <th :class="{ essential: editMode }">GARMENT<br />ORDER QTY</th>
          <th :class="{ essential: editMode }">UNIT PRICE</th>
          <th>CUT & MAKING</th>
          <th>PACKING</th>
          <th>HANDLING</th>
          <th>COMMISSION</th>
          <th :class="{ essential: editMode }">CURRENCY</th>
          <th :class="{ essential: editMode }">SHIP DATE</th>
          <th :class="{ essential: editMode }">MANUFACTURER</th>
          <th>COO</th>
          <th>MODIFIED<br />DATE</th>
          <th>MODIFIED<br />BY</th>
          <th v-if="editMode"></th>
        </tr>
      </thead>
      <tbody>
        <template v-if="hasValue(salesAmount.cbdTitle)">
          <tr v-if="editMode">
            <td colspan="100" class="register pointer" @click="add">
              <v-btn x-small color="secondary" :elevation="0">ADD</v-btn> NEW FLOW LINE
            </td>
          </tr>
          <tr
            v-for="(flow, flowIndex) in salesAmount.financialInitialSalesQtySelectDtoList"
            :key="`tr expanded flow ${salesAmount.cbdUUID} ${flowIndex}`">
            <td>{{ `Flow ${flowIndex + 1}` }}</td>
            <td :class="{ required: editMode && !hasValue(flow.garmentOrderQty) }">
              <span v-if="!editMode"> {{ numberWithComma(flow.garmentOrderQty) }}</span>
              <span v-else>
                <v-text-field
                  type="number"
                  v-model="flow.garmentOrderQty"
                  outlined
                  dense
                  hide-details
                  @keyup="changedFlowOrderQty(flow)"
                  @change="changedFlowOrderQty(flow)" />
              </span>
            </td>
            <td :class="{ required: editMode && !hasValue(flow.finalPrice) }">
              <span v-if="!editMode"> {{ numberWithComma(flow.finalPrice) }}</span>
              <span v-else>
                <v-text-field
                  type="number"
                  v-model="flow.finalPrice"
                  outlined
                  dense
                  hide-details
                  @keyup="changedFlowFinalPrice(flow)"
                  @change="changedFlowFinalPrice(flow)" />
              </span>
            </td>
            <td>
              <span v-if="!editMode"> {{ numberWithComma(flow.cutMakingAmt) }}</span>
              <span v-else>
                <v-text-field
                  v-if="hasValue(flow.garmentOrderQty)"
                  type="number"
                  v-model="flow.cutMakingAmt"
                  outlined
                  dense
                  hide-details />
                <v-text-field v-else disabled outlined dense hide-details />
              </span>
            </td>
            <td>
              <span v-if="!editMode"> {{ numberWithComma(flow.packingAmt) }}</span>
              <span v-else>
                <v-text-field
                  v-if="hasValue(flow.garmentOrderQty)"
                  type="number"
                  v-model="flow.packingAmt"
                  outlined
                  dense
                  hide-details />
                <v-text-field v-else disabled outlined dense hide-details />
              </span>
            </td>
            <td>
              <span v-if="!editMode"> {{ numberWithComma(flow.handlingAmt) }}</span>
              <span v-else>
                <v-text-field
                  v-if="hasValue(flow.garmentOrderQty)"
                  type="number"
                  v-model="flow.handlingAmt"
                  outlined
                  dense
                  hide-details />
                <v-text-field v-else disabled outlined dense hide-details />
              </span>
            </td>
            <td>
              <span v-if="!editMode"> {{ numberWithComma(flow.commissionAmt) }}</span>
              <span v-else>
                <v-text-field
                  v-if="hasValue(flow.garmentOrderQty)"
                  type="number"
                  v-model="flow.commissionAmt"
                  outlined
                  dense
                  hide-details />
                <v-text-field v-else disabled outlined dense hide-details />
              </span>
            </td>
            <td>
              <span> {{ flow.finalPriceUom }}</span>
            </td>
            <td :class="{ required: editMode && !hasValue(flow.shipDate) }">
              <span v-if="!editMode"> {{ $m(flow.shipDate).format("yyyy-MM-DD") }}</span>
              <input-date v-else :open.sync="flow.shipDateShow" :date.sync="flow.shipDate" :required="true" />
            </td>
            <td :class="{ required: editMode && !hasValue(flow.manufactureCmpy) }">
              <span v-if="!editMode || flowIndex !== 0"> {{ flow.manufactureCmpy }}</span>
              <span v-else>
                <v-autocomplete
                  dense
                  solo
                  hide-details
                  flat
                  outlined
                  :items="manufactureList"
                  item-text="name"
                  item-value="cmpyUUID"
                  v-model="flow.manufactureCmpyUUID"
                  @change="manufactureChanged(flow)">
                  <template v-slot:append>
                    <v-icon> mdi-chevron-down </v-icon>
                  </template>
                  <template v-slot:label>
                    <span class="font-error">Required</span>
                  </template>
                </v-autocomplete>
              </span>
            </td>
            <td>
              <span> {{ flow.coo }}</span>
            </td>
            <td>
              <span v-if="!editMode"> {{ $m(flow.sysUdtDt).format("yyyy-MM-DD") }}</span>
            </td>
            <td>
              <span v-if="!editMode"> {{ flow.workedBy }}</span>
            </td>
            <td v-if="editMode">
              <v-btn color="secondary" outlined fab x-small @click="remove(flowIndex)">
                <v-icon> mdi-minus </v-icon>
              </v-btn>
            </td>
          </tr>
          <tr class="total" v-if="!editMode">
            <td>TOTAL</td>
            <td>
              {{
                numberWithComma(
                  salesAmount.financialInitialSalesQtySelectDtoList
                    .map(i => i.garmentOrderQty)
                    .reduce((partialSum, a) => partialSum + (a || 0), 0),
                )
              }}
            </td>
            <td></td>
            <td>
              {{
                numberWithComma(
                  fixedNumber(
                    salesAmount.financialInitialSalesQtySelectDtoList
                      .map(i => i.cutMakingAmt)
                      .reduce((partialSum, a) => partialSum + (a || 0), 0),
                    $mathFix,
                  ),
                )
              }}
            </td>
            <td>
              {{
                numberWithComma(
                  fixedNumber(
                    salesAmount.financialInitialSalesQtySelectDtoList
                      .map(i => i.packingAmt)
                      .reduce((partialSum, a) => partialSum + (a || 0), 0),
                    $mathFix,
                  ),
                )
              }}
            </td>
            <td>
              {{
                numberWithComma(
                  fixedNumber(
                    salesAmount.financialInitialSalesQtySelectDtoList
                      .map(i => i.handlingAmt)
                      .reduce((partialSum, a) => partialSum + (a || 0), 0),
                    $mathFix,
                  ),
                )
              }}
            </td>
            <td>
              {{
                numberWithComma(
                  fixedNumber(
                    salesAmount.financialInitialSalesQtySelectDtoList
                      .map(i => i.commissionAmt)
                      .reduce((partialSum, a) => partialSum + (a || 0), 0),
                    $mathFix,
                  ),
                )
              }}
            </td>
            <td>
              {{
                `${
                  salesAmount.financialInitialSalesQtySelectDtoList.length > 0
                    ? salesAmount.financialInitialSalesQtySelectDtoList[0].finalPriceUom
                    : ""
                }`
              }}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </template>
        <tr v-else>
          <td colspan="100" class="no-data text-center">
            CBD must be selected first to use this feature.
            <br />
            Click
            <v-btn color="secondary" outlined x-small @click="clickedSelectCBD(salesAmount)"> SELECT CBD </v-btn>
            to select CBD
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { calc, fixedNumber, hasValue, isNumber, numberWithComma, _deepCopy } from "@/utils/util";
import InputDate from "@/components/common/inputs/InputDate.vue";
import codeHandler from "@/utils/codeHandler";
import messageHandler from "@/utils/messageHandler";
export default {
  components: { InputDate },
  props: ["item", "manufactureList"],
  data() {
    return {
      editMode: false,
      saveLoading: false,
      backupSalesAmount: {},
      salesAmount: {},
    };
  },
  methods: {
    clickedSelectCBD(salesAmount) {
      this.$emit("clickedSelectCBD", salesAmount);
    },
    changeEditMode(val, canceled) {
      if (val) {
        this.backupSalesAmount = _deepCopy(this.salesAmount);
      } else {
        if (canceled) {
          this.salesAmount = _deepCopy(this.backupSalesAmount);
        } else {
          //
        }
      }

      this.editMode = val;
    },
    manufactureChanged(flow) {
      const manufacture = this.manufactureList.find(m => m.cmpyUUID === flow.manufactureCmpyUUID);
      this.salesAmount.financialInitialSalesQtySelectDtoList.forEach(flow => {
        flow.manufactureCmpy = manufacture?.name || "";
        flow.manufactureCmpyUUID = manufacture?.cmpyUUID || "";
        flow.coo = manufacture?.countryCode || "";
      });
    },
    add() {
      const qtyLength = this.salesAmount.financialInitialSalesQtySelectDtoList.length > 0;
      const cbd = this.salesAmount.financialInitialSalesCbdSelectDtoList.find(
        c => c.cbdUUID === this.salesAmount.cbdUUID,
      );
      this.salesAmount.financialInitialSalesQtySelectDtoList.push({
        accessoryAmt: 0,
        commissionAmt: 0,
        cutMakingAmt: 0,
        fabricAmt: 0,
        flow: 0,
        garmentOrderQty: undefined,
        handlingAmt: 0,
        packingAmt: 0,
        salesAmount: 0,
        shipDate: cbd?.shippingDate || "",
        shipDateShow: false,
        trimAmt: 0,
        coo: qtyLength ? this.salesAmount.financialInitialSalesQtySelectDtoList[0].coo || "" : "",
        finalPriceUom: cbd?.finalPriceUom || "",
        manufactureCmpy: qtyLength
          ? this.salesAmount.financialInitialSalesQtySelectDtoList[0].manufactureCmpy || ""
          : "",
        manufactureCmpyUUID: qtyLength
          ? this.salesAmount.financialInitialSalesQtySelectDtoList[0].manufactureCmpyUUID || ""
          : "",
        finalPrice: cbd?.finalPrice || 0,
      });
    },
    changedFlowOrderQty(flow) {
      if (hasValue(flow.garmentOrderQty)) {
        flow.cutMakingAmt = calc(
          parseFloat(flow.garmentOrderQty) * parseFloat(this.salesAmount.financialInitialSalesSelectDto.cutMakingRate),
        );
        flow.packingAmt = calc(
          parseFloat(flow.garmentOrderQty) * parseFloat(this.salesAmount.financialInitialSalesSelectDto.packingRate),
        );
        flow.handlingAmt = calc(
          parseFloat(flow.garmentOrderQty) * parseFloat(this.salesAmount.financialInitialSalesSelectDto.handlingRate),
        );
        flow.commissionAmt = calc(
          parseFloat(flow.garmentOrderQty) * parseFloat(this.salesAmount.financialInitialSalesSelectDto.commissionRate),
        );
      }

      this.setAmt(flow);
    },
    changedFlowFinalPrice(flow) {
      this.setAmt(flow);
    },
    setAmt(flow) {
      if (hasValue(flow.garmentOrderQty) && hasValue(flow.finalPrice)) {
        flow.fabricAmt = calc(
          parseFloat(flow.garmentOrderQty) *
            parseFloat(flow.finalPrice) *
            parseFloat(this.salesAmount.financialInitialSalesSelectDto.fabricRate),
        );
        flow.trimAmt = calc(
          parseFloat(flow.garmentOrderQty) *
            parseFloat(flow.finalPrice) *
            parseFloat(this.salesAmount.financialInitialSalesSelectDto.trimRate),
        );
        flow.accessoryAmt = calc(
          parseFloat(flow.garmentOrderQty) *
            parseFloat(flow.finalPrice) *
            parseFloat(this.salesAmount.financialInitialSalesSelectDto.accessoryRate),
        );
      }
    },
    remove(index) {
      this.salesAmount.financialInitialSalesQtySelectDtoList.splice(index, 1);
    },
    save() {
      if (!this.check()) return;

      this.saveLoading = true;
      this.$http
        .post("/financial/initial-sales-qty", {
          cmpyUUID: this.$store.getters.getCmpyUUID,
          bomUUID: this.salesAmount.bomUUID,
          cbdUUID: this.salesAmount.cbdUUID,
          financialInitialSalesQtyInsertDtoList: this.salesAmount.financialInitialSalesQtySelectDtoList.map((q, i) => ({
            ...q,
            flow: i + 1,
          })),
        })
        .then(res => {
          if (res.data.status === 200) {
            this.changeEditMode(false);
            this.$toast.success("Successfully saved");
            this.$emit("save", res.data.data);
          } else codeHandler(res.data);
        })
        .finally(() => (this.saveLoading = false));
    },
    check() {
      for (let flowIndex = 0; flowIndex < this.salesAmount.financialInitialSalesQtySelectDtoList.length; flowIndex++) {
        const flow = this.salesAmount.financialInitialSalesQtySelectDtoList[flowIndex];
        if (![flow.garmentOrderQty, flow.finalPrice, flow.shipDate, flow.manufactureCmpy].every(hasValue)) {
          messageHandler("required");
          return false;
        }
        if (
          ![
            flow.garmentOrderQty,
            flow.finalPrice,
            flow.cutMakingAmt,
            flow.packingAmt,
            flow.handlingAmt,
            flow.commissionAmt,
          ].every(item => isNumber(item, true))
        ) {
          messageHandler("number");
          return false;
        }
      }

      return true;
    },
    hasValue,
    numberWithComma,
    fixedNumber,
  },
  watch: {
    item(newValue) {
      this.salesAmount = newValue;
    },
  },
  created() {
    this.salesAmount = this.item;
  },
};
</script>

<style lang="scss" scoped>
td {
  text-align: center;
}

.essential {
  &::after {
    content: "*";
    position: relative;
    left: 4px;
    font-size: 12px;
    color: $error;
  }
}
</style>
