<template>
  <div class="financial-report">
    <div class="d-flex mb-4 gutter-mx-3 search-toolbar" style="justify-content: start">
      <div class="column-filter">
        <v-autocomplete dense solo hide-details flat outlined :items="termItems" v-model="selectedTerm">
          <template v-slot:append>
            <v-icon> mdi-chevron-down </v-icon>
          </template>
        </v-autocomplete>
      </div>

      <div class="column-filter">
        <v-autocomplete dense solo hide-details flat outlined :items="currencyItems" v-model="selectedCurrency">
          <template v-slot:append>
            <v-icon> mdi-chevron-down </v-icon>
          </template>
        </v-autocomplete>
      </div>

      <input-date :open.sync="startDateShow" :date.sync="startDate" :required="true" />
      <input-date :open.sync="endDateShow" :date.sync="endDate" :required="true" :min="startDate" />
    </div>

    <div class="custom-table">
      <table>
        <thead>
          <tr>
            <th rowspan="3">PHASE</th>
            <th colspan="5">AMOUNT EXPECTED TO BE PAID EACH WEEK</th>
            <th colspan="4">ACTUAL PAID AMOUNT</th>
          </tr>
          <tr>
            <th class="right">BASED ON SALES</th>
            <th colspan="2" class="right">BASED ON PO</th>
            <th colspan="2" class="right">BASED ON INVOICE</th>
            <th colspan="2" class="right">PAID AMOUNT</th>
            <th colspan="2" class="right">BALANCE AMOUNT</th>
          </tr>
          <tr>
            <th>L/C</th>
            <th>T/T</th>
            <th>L/C</th>
            <th>T/T</th>
            <th>L/C</th>
            <th>T/T</th>
            <th>L/C</th>
            <th>T/T</th>
            <th>L/C</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(financialReport, financialReportIndex) in financialReports">
            <tr :key="`tr ${financialReportIndex}`">
              <td>{{ financialReport.phase }}</td>
              <td v-html="toHTML(financialReport.sales_amt)"></td>
              <td v-html="toHTML(financialReport.po_tt_amt)"></td>
              <td v-html="toHTML(financialReport.po_lc_amt)"></td>
              <td v-html="toHTML(financialReport.invoice_tt_amt)"></td>
              <td v-html="toHTML(financialReport.invoice_lc_amt)"></td>
              <td v-html="toHTML(financialReport.paid_tt_amt)"></td>
              <td v-html="toHTML(financialReport.paid_lc_amt)"></td>
              <td v-html="toHTML(financialReport.balance_tt_amt)"></td>
              <td v-html="toHTML(financialReport.balance_lc_amt)"></td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import codeHandler from "@/utils/codeHandler";
import InputDate from "@/components/common/inputs/InputDate.vue";
import { hasValue, numberWithComma } from "@/utils/util";
import messageHandler from "@/utils/messageHandler";
export default {
  components: { InputDate },
  data() {
    return {
      financialReports: [],
      termItems: ["DAILY", "WEEKLY", "MONTHLY"],
      selectedTerm: "WEEKLY",
      currencyItems: ["ALL"],
      selectedCurrency: "ALL",
      startDateShow: false,
      endDateShow: false,
      startDate: "",
      endDate: "",
    };
  },
  methods: {
    getFinancialReport() {
      this.$emit("loadingChanged", true);

      this.$http
        .get("/financial/report", {
          data: {
            cmpyUUID: this.$store.getters.getCmpyUUID,
            periodBase: this.selectedTerm.toLowerCase(),
            currency: this.selectedCurrency,
            startDate: this.startDate,
            endDate: this.endDate,
          },
        })
        .then(res => {
          if (res.data.status === 200) {
            try {
              this.financialReports = JSON.parse(res.data.data);
            } catch (e) {
              console.error(e);
              messageHandler("error");
              this.financialReports = [];
            }
          } else codeHandler(res.data);
        })
        .finally(() => this.$emit("loadingChanged", false));
    },
    toHTML(str) {
      if (!hasValue(str) || str.toString() === "0") return "";
      return str.toString().replaceAll("|$|", "<br />");
    },
    hasValue,
    numberWithComma,
  },
  computed: {
    changeFilter() {
      const { selectedTerm, selectedCurrency, startDate, endDate } = this.$data;
      return [selectedTerm, selectedCurrency, startDate, endDate];
    },
  },
  watch: {
    changeFilter() {
      this.getFinancialReport();
    },
  },
  created() {
    this.startDate = `${new Date().getFullYear()}-01-01`;
    this.endDate = `${new Date().getFullYear()}-12-31`;
    this.currencyItems = ["ALL", ...this.$store.state.code.common.currency.map(c => c.itemName)];
    this.getFinancialReport();
  },
};
</script>

<style></style>
