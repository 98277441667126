<template>
  <div class="payment-date-recap mt-4">
    <div class="title d-flex">
      <h3>ADJUST VALUE FOR PAYMENT DATE OF INITIAL SALES ORDER RECAP</h3>
      <v-spacer />
      <template v-if="!editMode">
        <v-btn
          outlined
          color="secondary"
          small
          :disabled="!hasValue(salesAmount.cbdTitle)"
          @click="changeEditMode(true)">
          MODIFY
        </v-btn>
      </template>

      <template v-if="editMode">
        <v-btn outlined color="secondary" small :loading="saveLoading" @click="save"> SAVE </v-btn>
        <v-btn class="ml-1" outlined color="secondary" small @click="changeEditMode(false, true)"> CANCEL </v-btn>
      </template>
    </div>
    <table class="custom-table">
      <thead>
        <tr>
          <th></th>
          <th :class="{ essential: editMode }">FABRIC</th>
          <th :class="{ essential: editMode }">TRIM</th>
          <th :class="{ essential: editMode }">ACCESSORY</th>
          <th :class="{ essential: editMode }">CUT & MAKING</th>
          <th :class="{ essential: editMode }">PACKING</th>
          <th :class="{ essential: editMode }">HANDLING</th>
          <th :class="{ essential: editMode }">COMMISSION</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="hasValue(salesAmount.cbdTitle)">
          <td class="th">GARMENT<br />SHIP DATE</td>
          <td
            :class="{
              required:
                editMode &&
                (!hasValue(salesAmount.financialInitialSalesSelectDto.fabricDaysSign) ||
                  !hasValue(salesAmount.financialInitialSalesSelectDto.fabricDays)),
            }">
            <span
              v-if="!editMode"
              v-html="
                getPaymentDateHTML(
                  salesAmount.financialInitialSalesSelectDto.fabricDaysSign,
                  salesAmount.financialInitialSalesSelectDto.fabricDays,
                )
              "></span>
            <span v-else class="flex-center">
              <div class="days-sign">
                <v-autocomplete
                  dense
                  solo
                  hide-details
                  flat
                  outlined
                  :items="paymentDateSelectItem"
                  v-model="salesAmount.financialInitialSalesSelectDto.fabricDaysSign">
                  <template v-slot:append>
                    <v-icon> mdi-chevron-down </v-icon>
                  </template>
                </v-autocomplete>
              </div>
              <div class="days">
                <!-- <v-text-field
                  type="number"
                  v-model="salesAmount.financialInitialSalesSelectDto.fabricDays"
                  outlined
                  dense
                  hide-details /> -->
                <input-number v-model="salesAmount.financialInitialSalesSelectDto.fabricDays"></input-number>
              </div>
              DAYS
            </span>
          </td>
          <td
            :class="{
              required:
                editMode &&
                (!hasValue(salesAmount.financialInitialSalesSelectDto.trimDaysSign) ||
                  !hasValue(salesAmount.financialInitialSalesSelectDto.trimDays)),
            }">
            <span
              v-if="!editMode"
              v-html="
                getPaymentDateHTML(
                  salesAmount.financialInitialSalesSelectDto.trimDaysSign,
                  salesAmount.financialInitialSalesSelectDto.trimDays,
                )
              "></span>
            <span v-else class="flex-center">
              <div class="days-sign">
                <v-autocomplete
                  dense
                  solo
                  hide-details
                  flat
                  outlined
                  :items="paymentDateSelectItem"
                  v-model="salesAmount.financialInitialSalesSelectDto.trimDaysSign">
                  <template v-slot:append>
                    <v-icon> mdi-chevron-down </v-icon>
                  </template>
                </v-autocomplete>
              </div>
              <div class="days">
                <!-- <v-text-field
                  type="number"
                  v-model="salesAmount.financialInitialSalesSelectDto.trimDays"
                  outlined
                  dense
                  hide-details /> -->
                <input-number v-model="salesAmount.financialInitialSalesSelectDto.trimDays"></input-number>
              </div>
              DAYS
            </span>
          </td>
          <td
            :class="{
              required:
                editMode &&
                (!hasValue(salesAmount.financialInitialSalesSelectDto.accessoryDaysSign) ||
                  !hasValue(salesAmount.financialInitialSalesSelectDto.accessoryDays)),
            }">
            <span
              v-if="!editMode"
              v-html="
                getPaymentDateHTML(
                  salesAmount.financialInitialSalesSelectDto.accessoryDaysSign,
                  salesAmount.financialInitialSalesSelectDto.accessoryDays,
                )
              "></span>
            <span v-else class="flex-center">
              <div class="days-sign">
                <v-autocomplete
                  dense
                  solo
                  hide-details
                  flat
                  outlined
                  :items="paymentDateSelectItem"
                  v-model="salesAmount.financialInitialSalesSelectDto.accessoryDaysSign">
                  <template v-slot:append>
                    <v-icon> mdi-chevron-down </v-icon>
                  </template>
                </v-autocomplete>
              </div>
              <div class="days">
                <!-- <v-text-field
                  type="number"
                  v-model="salesAmount.financialInitialSalesSelectDto.accessoryDays"
                  outlined
                  dense
                  hide-details /> -->
                <input-number v-model="salesAmount.financialInitialSalesSelectDto.accessoryDays"></input-number>
              </div>
              DAYS
            </span>
          </td>
          <td
            :class="{
              required:
                editMode &&
                (!hasValue(salesAmount.financialInitialSalesSelectDto.cutMakingDaysSign) ||
                  !hasValue(salesAmount.financialInitialSalesSelectDto.cutMakingDays)),
            }">
            <span
              v-if="!editMode"
              v-html="
                getPaymentDateHTML(
                  salesAmount.financialInitialSalesSelectDto.cutMakingDaysSign,
                  salesAmount.financialInitialSalesSelectDto.cutMakingDays,
                )
              "></span>
            <span v-else class="flex-center">
              <div class="days-sign">
                <v-autocomplete
                  dense
                  solo
                  hide-details
                  flat
                  outlined
                  :items="paymentDateSelectItem"
                  v-model="salesAmount.financialInitialSalesSelectDto.cutMakingDaysSign">
                  <template v-slot:append>
                    <v-icon> mdi-chevron-down </v-icon>
                  </template>
                </v-autocomplete>
              </div>
              <div class="days">
                <!-- <v-text-field
                  type="number"
                  v-model="salesAmount.financialInitialSalesSelectDto.cutMakingDays"
                  outlined
                  dense
                  hide-details /> -->
                <input-number v-model="salesAmount.financialInitialSalesSelectDto.cutMakingDays"></input-number>
              </div>
              DAYS
            </span>
          </td>
          <td
            :class="{
              required:
                editMode &&
                (!hasValue(salesAmount.financialInitialSalesSelectDto.packingDaysSign) ||
                  !hasValue(salesAmount.financialInitialSalesSelectDto.packingDays)),
            }">
            <span
              v-if="!editMode"
              v-html="
                getPaymentDateHTML(
                  salesAmount.financialInitialSalesSelectDto.packingDaysSign,
                  salesAmount.financialInitialSalesSelectDto.packingDays,
                )
              "></span>
            <span v-else class="flex-center">
              <div class="days-sign">
                <v-autocomplete
                  dense
                  solo
                  hide-details
                  flat
                  outlined
                  :items="paymentDateSelectItem"
                  v-model="salesAmount.financialInitialSalesSelectDto.packingDaysSign">
                  <template v-slot:append>
                    <v-icon> mdi-chevron-down </v-icon>
                  </template>
                </v-autocomplete>
              </div>
              <div class="days">
                <!-- <v-text-field
                  type="number"
                  v-model="salesAmount.financialInitialSalesSelectDto.packingDays"
                  outlined
                  dense
                  hide-details /> -->
                <input-number v-model="salesAmount.financialInitialSalesSelectDto.packingDays"></input-number>
              </div>
              DAYS
            </span>
          </td>
          <td
            :class="{
              required:
                editMode &&
                (!hasValue(salesAmount.financialInitialSalesSelectDto.handlingDaysSign) ||
                  !hasValue(salesAmount.financialInitialSalesSelectDto.handlingDays)),
            }">
            <span
              v-if="!editMode"
              v-html="
                getPaymentDateHTML(
                  salesAmount.financialInitialSalesSelectDto.handlingDaysSign,
                  salesAmount.financialInitialSalesSelectDto.handlingDays,
                )
              "></span>
            <span v-else class="flex-center">
              <div class="days-sign">
                <v-autocomplete
                  dense
                  solo
                  hide-details
                  flat
                  outlined
                  :items="paymentDateSelectItem"
                  v-model="salesAmount.financialInitialSalesSelectDto.handlingDaysSign">
                  <template v-slot:append>
                    <v-icon> mdi-chevron-down </v-icon>
                  </template>
                </v-autocomplete>
              </div>
              <div class="days">
                <!-- <v-text-field
                  type="number"
                  v-model="salesAmount.financialInitialSalesSelectDto.handlingDays"
                  outlined
                  dense
                  hide-details /> -->
                <input-number v-model="salesAmount.financialInitialSalesSelectDto.handlingDays"></input-number>
              </div>
              DAYS
            </span>
          </td>
          <td
            :class="{
              required:
                editMode &&
                (!hasValue(salesAmount.financialInitialSalesSelectDto.commissionDaysSign) ||
                  !hasValue(salesAmount.financialInitialSalesSelectDto.commissionDays)),
            }">
            <span
              v-if="!editMode"
              v-html="
                getPaymentDateHTML(
                  salesAmount.financialInitialSalesSelectDto.commissionDaysSign,
                  salesAmount.financialInitialSalesSelectDto.commissionDays,
                )
              "></span>
            <span v-else class="flex-center">
              <div class="days-sign">
                <v-autocomplete
                  dense
                  solo
                  hide-details
                  flat
                  outlined
                  :items="paymentDateSelectItem"
                  v-model="salesAmount.financialInitialSalesSelectDto.commissionDaysSign">
                  <template v-slot:append>
                    <v-icon> mdi-chevron-down </v-icon>
                  </template>
                </v-autocomplete>
              </div>
              <div class="days">
                <!-- <v-text-field
                  type="number"
                  v-model="salesAmount.financialInitialSalesSelectDto.commissionDays"
                  outlined
                  dense
                  hide-details /> -->
                <input-number v-model="salesAmount.financialInitialSalesSelectDto.commissionDays"></input-number>
              </div>
              DAYS
            </span>
          </td>
        </tr>
        <tr v-else>
          <td colspan="100" class="no-data text-center">
            CBD must be selected first to use this feature.
            <br />
            Click
            <v-btn color="secondary" outlined x-small @click="clickedSelectCBD(salesAmount)"> SELECT CBD </v-btn>
            to select CBD
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { hasValue, isNumber, _deepCopy } from "@/utils/util";
import codeHandler from "@/utils/codeHandler";
import messageHandler from "@/utils/messageHandler";
import InputNumber from "@/components/common/inputs/InputNumber.vue";

export default {
  props: ["item"],
  components: { InputNumber },
  data() {
    return {
      editMode: false,
      saveLoading: false,
      salesAmount: {},
      backupSalesAmount: {},
      paymentDateSelectItem: [
        {
          text: "PLUS",
          value: "+",
          html: "PLUS (<span class='font-blue1'>+</span>)",
        },
        {
          text: "MINUS",
          value: "-",
          html: "MINUS (<span class='font-error'>-</span>)",
        },
      ],
    };
  },
  methods: {
    clickedSelectCBD(salesAmount) {
      this.$emit("clickedSelectCBD", salesAmount);
    },
    getPaymentDateHTML(sign, day) {
      const signHTML = this.paymentDateSelectItem.find(i => i.value === sign)?.html || "";
      const dayHTML = (day || 0) + " DAYS";
      return signHTML + " " + dayHTML;
    },
    changeEditMode(val, canceled) {
      if (val) {
        this.backupSalesAmount = _deepCopy(this.salesAmount);
      } else {
        if (canceled) {
          this.salesAmount = _deepCopy(this.backupSalesAmount);
        } else {
          //
        }
      }

      this.editMode = val;
    },
    save() {
      if (!this.check()) return;

      this.saveLoading = true;
      this.$http
        .put("/financial/initial-sales", {
          cmpyUUID: this.$store.getters.getCmpyUUID,
          bomUUID: this.salesAmount.bomUUID,
          cbdUUID: this.salesAmount.cbdUUID,
          financialInitialSalesUpdateDto: {
            ...this.salesAmount.financialInitialSalesSelectDto,
          },
        })
        .then(res => {
          if (res.data.status === 200) {
            this.$toast.success("Successfully saved");
            this.changeEditMode(false);
          } else codeHandler(res.data);
        })
        .finally(() => (this.saveLoading = false));
    },
    check() {
      if (
        ![
          this.salesAmount.financialInitialSalesSelectDto.fabricDaysSign,
          this.salesAmount.financialInitialSalesSelectDto.trimDaysSign,
          this.salesAmount.financialInitialSalesSelectDto.accessoryDaysSign,
          this.salesAmount.financialInitialSalesSelectDto.cutMakingDaysSign,
          this.salesAmount.financialInitialSalesSelectDto.packingDaysSign,
          this.salesAmount.financialInitialSalesSelectDto.handlingDaysSign,
          this.salesAmount.financialInitialSalesSelectDto.commissionDaysSign,
          this.salesAmount.financialInitialSalesSelectDto.fabricDays,
          this.salesAmount.financialInitialSalesSelectDto.trimDays,
          this.salesAmount.financialInitialSalesSelectDto.accessoryDays,
          this.salesAmount.financialInitialSalesSelectDto.cutMakingDays,
          this.salesAmount.financialInitialSalesSelectDto.packingDays,
          this.salesAmount.financialInitialSalesSelectDto.handlingDays,
          this.salesAmount.financialInitialSalesSelectDto.commissionDays,
        ].every(hasValue)
      ) {
        messageHandler("required");
        return false;
      }

      if (
        ![
          this.salesAmount.financialInitialSalesSelectDto.fabricDays,
          this.salesAmount.financialInitialSalesSelectDto.trimDays,
          this.salesAmount.financialInitialSalesSelectDto.accessoryDays,
          this.salesAmount.financialInitialSalesSelectDto.cutMakingDays,
          this.salesAmount.financialInitialSalesSelectDto.packingDays,
          this.salesAmount.financialInitialSalesSelectDto.handlingDays,
          this.salesAmount.financialInitialSalesSelectDto.commissionDays,
        ].every(isNumber)
      ) {
        messageHandler("number");
        return false;
      }

      return true;
    },
    hasValue,
  },
  watch: {
    item(newValue) {
      this.salesAmount = newValue;
    },
  },
  created() {
    this.salesAmount = this.item;
  },
};
</script>

<style lang="scss" scoped>
td {
  text-align: center !important;

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .days-sign {
    width: 70px;
  }

  .days {
    width: 50px;
  }
}

.essential {
  &::after {
    content: "*";
    position: relative;
    left: 4px;
    font-size: 12px;
    color: $error;
  }
}
</style>
