<template>
  <v-dialog
    v-model="dialogShow"
    min-width="400px"
    max-width="1000px"
    content-class="company select-cbd customEditModal">
    <v-card class="pa-5">
      <h1>SELECT CBD</h1>

      <div class="mt-10 content">
        <div class="custom-table">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Name (Description)</th>
                <th>FINAL PRICE</th>
                <th>Profit</th>
                <th>Qty</th>
                <th>Modified</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><input type="radio" v-model="selectedCBDUUID" value="" disabled /></td>
                <td>Not Selected</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr v-for="(cbd, cbdIndex) in cbdList" :key="cbdIndex">
                <td><input type="radio" v-model="selectedCBDUUID" :value="cbd.cbdUUID" /></td>
                <td>
                  <span class="pointer underline" @click="moveCBD(cbd.cbdUUID)">{{ cbd.cbdTitle }}</span>
                </td>
                <td>{{ `${cbd.finalPriceUom} ${numberWithComma(cbd.finalPrice)}` }}</td>
                <td>{{ `${fixedNumber(cbd.profit, $mathFix)}%` }}</td>
                <td>{{ numberWithComma(cbd.orderQty) }}</td>
                <td>
                  <span>
                    {{ $m(cbd.sysUdtDt).format("yyyy-MM-DD HH:mm") }}
                  </span>
                  <br />
                  <span class="font-blue1">
                    {{ cbd.udtUserName }}
                  </span>
                </td>
                <td :class="cbd.status === 'OPEN' ? 'font-blue1' : 'font-error'">
                  {{ cbd.status }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="actions d-flex justify-center">
        <v-btn :elevation="0" color="secondary" :loading="saveLoading" @click="save"> SAVE </v-btn>
        <v-btn :elevation="0" color="secondary" @click="dialogShow = false">CANCEL</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { hasValue, numberWithComma, fixedNumber } from "@/utils/util";
import codeHandler from "@/utils/codeHandler";
import messageHandler from "@/utils/messageHandler";
export default {
  props: ["show", "cbdList", "selectCBDUUID", "bomUUID"],
  data() {
    return {
      saveLoading: false,
      selectedCBDUUID: "",
    };
  },
  methods: {
    save() {
      if (![this.bomUUID].every(hasValue)) return messageHandler("error");

      this.saveLoading = true;
      this.$http
        .post("/financial/initial-sales", {
          cmpyUUID: this.$store.getters.getCmpyUUID,
          bomUUID: this.bomUUID,
          cbdUUID: this.selectedCBDUUID,
        })
        .then(res => {
          if (res.data.status === 200) {
            this.$emit("save", res.data.data);
          } else codeHandler(res.data);
        })
        .finally(() => (this.saveLoading = false));
    },
    moveCBD(cbdUUID) {
      let routeLink = this.$router.resolve({ name: "CBD", params: { cbdUUID } });
      window.open(routeLink.href, "_blank");
    },
    numberWithComma,
    fixedNumber,
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);

        if (value === false) {
          this.selectedCBDUUID = this.selectCBDUUID || "";
        }
      },
    },
  },
  watch: {
    selectCBDUUID(newVal) {
      this.selectedCBDUUID = newVal || "";
    },
  },
};
</script>

<style></style>
