<template>
  <div class="initial-sales-amount">
    <div class="d-flex mb-4 gutter-mx-3 search-toolbar" style="justify-content: start">
      <!-- Column Select -->
      <div class="column-filter">
        <v-autocomplete dense solo hide-details flat outlined :items="searchColumns" v-model="searchColumn">
          <template v-slot:append>
            <v-icon> mdi-chevron-down </v-icon>
          </template>
        </v-autocomplete>
      </div>

      <!-- Column Search -->
      <div class="search">
        <v-text-field
          dense
          outlined
          hide-details
          v-model="searchText"
          placeholder="Insert Keyword to search"
          background-color="white"
          @keyup.enter="getinitialSalesAmounts(true)"></v-text-field>
      </div>

      <v-btn color="secondary" icon @click="getinitialSalesAmounts(true)" style="margin: 0; align-self: center">
        <v-icon> mdi-magnify </v-icon>
      </v-btn>
    </div>

    <div class="custom-table">
      <table>
        <thead>
          <tr>
            <th
              v-for="column in tableColumns"
              :key="`thead ${column.text}`"
              :class="{ pointer: column.canSort !== false }"
              @click="column.canSort !== false ? changeSort(column.value) : ''">
              <span>{{ column.text }}</span>

              <i class="fa-solid fa-sort-down" v-if="sort.key === column.value && sort.order === 'desc'"></i>
              <i class="fa-solid fa-sort-up" v-if="sort.key === column.value && sort.order === 'asc'"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(salesAmount, salesAmountIndex) in initialSalesAmounts">
            <tr :key="`tr ${salesAmountIndex}`">
              <td v-for="(column, columnIndex) in tableColumns" :key="`td ${column.text} ${columnIndex}`">
                <span v-if="column.excludeMatching !== true">
                  {{ salesAmount[column.value] }}
                </span>

                <!-- No -->
                <span v-else-if="column.value === 'sysRegDt'">
                  <template v-if="sort.key === column.value && sort.order === 'asc'">
                    {{ (page - 1) * itemsPerPage + (salesAmountIndex + 1) }}
                  </template>
                  <template v-else>
                    {{ totalCnt - salesAmountIndex - (page - 1) * itemsPerPage }}
                  </template>
                </span>

                <!-- Style -->
                <div v-else-if="column.value === 'styleNo'">
                  <div
                    v-for="(styleNo, styleIndex) in salesAmount[column.value].split('|$|')"
                    :key="`${column.value} ${salesAmountIndex} ${styleIndex}`">
                    {{ styleNo }}
                  </div>
                </div>

                <!-- BOM TITLE -->
                <span
                  v-else-if="column.value === 'coverName'"
                  class="pointer underline"
                  @click="searchBOM(salesAmount.bomUUID)">
                  {{ salesAmount[column.value] }}
                </span>

                <!-- CBDTitle -->
                <template v-else-if="column.value === 'cbdTitle'">
                  <span
                    v-if="hasValue(salesAmount[column.value])"
                    class="pointer underline"
                    @click="clickedSelectCBD(salesAmount)">
                    {{ salesAmount[column.value] }}
                  </span>

                  <v-btn v-else outlined small color="secondary" @click="clickedSelectCBD(salesAmount)">
                    SELECT CBD
                  </v-btn>
                </template>

                <!-- Balance Qty -->
                <span v-else-if="column.value === 'balanceQty'">
                  {{ numberWithComma(fixedNumber(salesAmount[column.value], $mathFix, true)) }}
                </span>

                <!-- More Data -->
                <v-btn
                  v-else-if="column.value === 'expanded'"
                  color="secondary"
                  outlined
                  small
                  fab
                  @click="salesAmount[column.value] = !salesAmount[column.value]">
                  <v-icon v-if="salesAmount[column.value]">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr v-if="salesAmount.expanded" :key="`tr expanded ${salesAmountIndex}`" class="expanded-row">
              <td colspan="100">
                <order-recap-table
                  :item="salesAmount"
                  :manufactureList="manufactureList"
                  @clickedSelectCBD="clickedSelectCBD"
                  @save="orderRecapSaved" />

                <payment-date-table :item="salesAmount" @clickedSelectCBD="clickedSelectCBD" />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <div class="text-center pt-2" v-if="pageCount > 0">
      <v-pagination v-model="page" :length="pageCount" color="secondary" :total-visible="$pagePV"></v-pagination>
    </div>

    <select-CBD
      :show.sync="selectCBDShow"
      :cbdList="[...selectCBDList]"
      :selectCBDUUID="selectCBDUUID"
      :bomUUID="selectCBDBOMUUID"
      @save="selectCBDSave" />
  </div>
</template>

<script>
import codeHandler from "@/utils/codeHandler";
import { hasValue, numberWithComma, fixedNumber } from "@/utils/util";
import SelectCBD from "@/components/company/financialPlan/initialSalesAmount/SelectCBD.vue";
import PaymentDateTable from "@/components/company/financialPlan/initialSalesAmount/PaymentDateTable.vue";
import OrderRecapTable from "@/components/company/financialPlan/initialSalesAmount/OrderRecapTable.vue";

export default {
  components: {
    SelectCBD,
    PaymentDateTable,
    OrderRecapTable,
  },
  data() {
    return {
      initialSalesAmounts: [],
      manufactureList: [],
      searchColumn: "ALL",
      searchText: "",
      page: 1,
      totalCnt: 0,
      itemsPerPage: 10,
      sort: { key: "sysRegDt", order: "desc" },
      selectCBDShow: false,
      selectCBDList: [],
      selectCBDBOMUUID: "",
      selectCBDUUID: "",
      columns: [
        //text : thead 표시
        //value : field Name
        //tableShow : table 표시 여부
        //searchShow : 필터 셀렉트박스 표시 여부
        //excludeMatching : 테이블 표시시 별도 컨트롤 필요 여부 (false일 경우 value로 단순표기 가능)
        //canSort : 정렬 가능 여부
        { text: "ALL", value: "ALL", tableShow: false },
        { text: "No", value: "sysRegDt", searchShow: false, excludeMatching: true },
        { text: "BRAND", value: "offerBrand" },
        { text: "YEAR", value: "bomYear" },
        { text: "SEASON", value: "season" },
        { text: "GENDER", value: "gender" },
        { text: "GARMENT FABRIC TYPE", value: "fabricType" },
        { text: "GARMENT TYPE", value: "garmentType" },
        { text: "DESIGN #", value: "designNo" },
        { text: "STYLE #", value: "styleNo", excludeMatching: true },
        { text: "ORDER TYPE", value: "orderType" },
        { text: "BOM TITLE", value: "coverName", excludeMatching: true },
        { text: "CBD TITLE", value: "cbdTitle", excludeMatching: true },
        { text: "BALANCE QTY", value: "balanceQty", searchShow: false, excludeMatching: true },
        { text: "MORE DATA", value: "expanded", searchShow: false, canSort: false, excludeMatching: true },
      ],
    };
  },
  methods: {
    getinitialSalesAmounts(changeFilters) {
      this.$emit("loadingChanged", true);
      if (changeFilters) this.page = 1;

      this.$http
        .get("/financial/initial-sales-amount", {
          data: {
            cmpyUUID: this.$store.getters.getCmpyUUID,
            currentPageNo: this.page,
            filterColumn: this.searchColumn,
            searchText: this.searchText,
            sortColumn: this.sort.key,
            sortType: this.sort.order,
          },
        })
        .then(res => {
          if (res.data.status === 200) {
            if (Array.isArray(res.data.data)) {
              this.initialSalesAmounts = res.data.data.map(i => ({
                ...i,
                expanded: false,
              }));
              this.totalCnt = res.data.data[0]?.totalCnt || 0;
            }
          } else codeHandler(res.data);
        })
        .finally(() => this.$emit("loadingChanged", false));
    },
    getManufactures() {
      this.$http.get("company/list").then(res => {
        if (res.data.status === 200)
          this.manufactureList = [...res.data.data?.filter(d => d.cmpyType === "MANUFACTURER")];
        else codeHandler(res.data);
      });
    },
    changeSort(value) {
      if (this.sort.key === value) {
        this.sort.order = this.sort.order === "desc" ? "asc" : "desc";
      } else {
        this.sort.key = value;
        this.sort.order = "desc";
      }
      this.getinitialSalesAmounts(true);
    },
    clickedSelectCBD(bom) {
      this.selectCBDList = bom.financialInitialSalesCbdSelectDtoList || [];
      this.selectCBDUUID = bom.cbdUUID;
      this.selectCBDBOMUUID = bom.bomUUID;
      this.selectCBDShow = true;
    },
    selectCBDSave(bom) {
      this.changeBOM(bom);
      this.selectCBDShow = false;
    },
    orderRecapSaved(bom) {
      this.changeBOM(bom);
    },
    changeBOM(bom) {
      const index = this.initialSalesAmounts.findIndex(i => i.bomUUID === bom.bomUUID);
      if (index !== 1) Object.assign(this.initialSalesAmounts[index], bom);
    },
    searchBOM(bomUUID) {
      let routeLink = this.$router.resolve({
        name: "BOM",
        query: {
          bomUUID,
        },
      });
      window.open(routeLink.href, "_blank");
    },
    hasValue,
    numberWithComma,
    fixedNumber,
  },
  computed: {
    tableColumns() {
      return this.columns.filter(c => c.tableShow !== false);
    },
    searchColumns() {
      return this.columns.filter(c => c.searchShow !== false);
    },
    pageCount() {
      return Math.ceil((this.totalCnt || 0) / this.itemsPerPage);
    },
  },
  watch: {
    page() {
      this.getinitialSalesAmounts();
    },
  },
  created() {
    this.getinitialSalesAmounts();
    this.getManufactures();
  },
};
</script>

<style></style>
