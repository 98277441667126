<template>
  <input type="text" :value="number" @input="handleInput" />
</template>

<script>
export default {
  data() {
    return {
      number: "",
    };
  },
  methods: {
    handleInput(event) {
      this.number = event.target.value;
    },
  },
  watch: {
    number(val) {
      this.number = val.replace(/[^0-9]/g, "");
      this.$emit("input", this.number);
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  font-size: inherit;
  //line-height: 20px;
  padding: 0 12px;
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 0.3125rem;
  height: 24px;

  &:focus {
    outline-color: #dddddd;
  }
}
</style>
